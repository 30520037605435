
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

































































.net-antivirus__hero {
  ::v-deep .icon {
    width: 50rem;
    max-width: 80vw;
    height: auto;
    max-height: none;
    fill: $white;

    svg {
      width: 100%;
    }

    @include mq(xl) {
      width: 70rem;
    }
  }

  ::v-deep .hero__slides__item__title {
    @extend %visually-hidden;
  }
}

.custom-page__hero__btn {
  margin-top: $spacing * 2;
}

.net-antivirus__pricing__promo {
  margin-bottom: $spacing * 2;
}

.net-antivirus__pricing__promo__box {
  border-radius: 3px;
}

.net-antivirus__pricing__promo__text {
  margin-left: $spacing / 2;
  color: $c-orange;
}

.net-antivirus__table {
  position: relative;
}

.compatible-devices__label {
  margin-bottom: $spacing;
  font-weight: 900;
  text-transform: uppercase;
}

.compatible-devices__logos {
  .icon {
    margin-right: $spacing;
    fill: $c-gray-darker;
  }

  @include mq(m) {
    .icon {
      margin-right: $spacing * 1.5;
    }
  }
}
