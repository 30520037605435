
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        











































































































































.net-antivirus-table {
  --header-height: 10rem;

  @extend %table-legacy;

  .table__layout {
    grid-template-rows: var(--header-height) 1fr 5rem;
  }

  .table-content {
    margin-bottom: 0;
  }

  .table-content__header__block {
    @extend %fw-bold;

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: var(--header-height);
    padding-bottom: $spacing;
    font-family: $ff-alt;
    text-align: center;

    .voo-logo {
      width: 20rem;

      @include mq(l) {
        width: 26rem;
      }
    }

    &:first-child {
      @extend %text-uppercase;

      color: $c-pink-medium;
    }
  }

  .table-content__data-outer {
    @include mq(l) {
      padding-bottom: $spacing * 3;
    }
  }
}
